<template>
        <!-- {{ getToReturn }} -->
        <!-- {{ adminrefactoListAnswers }} -->
    <DataTable  columnResizeMode="fit" editMode="cell" :value="this.adminrefactoListAnswers" responsiveLayout="scroll" :style="{ width: '100%' }">
      <Column field="answers.validityStatus" :style="{ width: '3em' }" v-if="((this.isValidatorPublicationRole() || this.isAdminRole() || this.isSimpleAdminRole()) && (getPlanList?.data[0].status === 'F_SUBMITTED'))">
        <template  #body="slotProps" >
          <div  @click="selectToReturn({toReturn: slotProps.data})">
            <!-- {{ this.getToReturn.filter(e=>e.id === slotProps.data.answers.id).length }} -->
            <!-- <Checkbox  @click="selectToReturn({toReturn: slotProps.data})"  :checked="true" /> -->
            <span v-if="this.getToReturn.filter(e=>e.id === slotProps.data.answers.id).length > 0">
              <input
                  id="checkbox"
                  type="checkbox"
                  :checked="true"
              />
            </span>
            <span v-else>
              <input
                  id="checkbox"
                  type="checkbox"
                  :checked="false"
              />
            </span>
          </div>
        </template>
      </Column>
      <Column field="answers.validityStatus" :style="{ width: '3em' }" v-if="((this.isValidatorPublicationRole() || this.isAdminRole() || this.isSimpleAdminRole()) && (getPlanList?.data[0].status === 'F_REJECTED'))">
      <template #body="slotProps">
        <!-- {{ slotProps.data.answers.validityStatus }} -->
        <i v-if="slotProps.data.answers.validityStatus === 'F_INVALID'" class="pi pi-times-circle" style="font-size: 1.5rem; color: red"></i>
      </template>
    </Column>
      <Column field="title" header="Libellé">
        <template #body="slotProps">
          <div class="p-d-flex p-ai-center p-jc-between" :style="{ gap: '1em' }">
            <span class="p-ml-2 truncate-custom" v-tooltip="slotProps.data.title">{{ slotProps.data.title }}</span>
            <!-- <svg
              v-if="slotProps.data.title"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-2em"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
              />
            </svg> -->
          </div>
        </template>
      </Column>
      <Column field="answers.forecast" header="Valeur " :style="{ width: '15em' }">
        <template #body="slotProps">
          <div class="p-ml-3">
            <!-- {{ slotProps.data.answers }} -->
            <span>{{ slotProps.data.answers.forecast }} {{ slotProps.data.answers.unit }}</span>
            <!-- <InputNumber
              decrementButtonClass="bg-blue-300"
              incrementButtonClass="bg-blue-300"
              required
              disabled
              :min="0"
              :class="{ 'p-invalid': this.invalidQuestionIds.includes(slotProps.data.answers.questionId) }"
              showButtons
              :style="{ 'margin-top': '1em' }"
              id="nbHours"
              v-model="slotProps.data.answers.forecast"
            /> -->
            <p>
              <small
                :style="{ color: '#ef3636', fontStyle: 'italic', marginLeft: '0.5em' }"
                v-if="this.invalidQuestionIds.includes(slotProps.data.answers.questionId)"
                >La valeur doit être supérieure ou égale à 0.</small
              >
            </p>
          </div>
        </template>
      </Column>
      <Column field="answers.comment" header="Observations" :style="{ width: '42em' }">
        <template #body="slotProps">
          <div>
            <OverlayPanel
              appendTo="body"
              :showCloseIcon="true"
              :id="'obs_' + slotProps.data.answers.questionId"
              :style="{ width: '520px' }"
              :ref="
                slotProps.data.answers.questionId
                  ? 'comment_' + slotProps.data.answers.questionId
                  : 'comment_' + slotProps.data.answers.id
              "
            >
              <div class="p-d-flex p-ai-center">
                <label class="p-my-3" for="comment"> Observation : </label>
              </div>
              <Textarea
                id="comment"
                autofocus
                v-model="slotProps.data.answers.comment"
                :autoResize="true"
                :style="{ width: '100%' }"
                rows="5"
                cols="30"
              />
            </OverlayPanel>
          </div>
          <div
            class="p-pl-3 p-d-flex p-jc-between p-ai-center"
            :style="{ width: '100%', height: '100%', cursor: 'pointer' }"
          >
            <span
              :style="{ width: '90%' }"
              class="truncate-custom"
              v-tooltip.top="slotProps.data.answers.comment"
              v-text="slotProps.data.answers.comment"
            ></span>
            <!-- <Button icon="pi pi-pencil" class="p-button-rounded p-button-outlined" /> -->
          </div>
        </template>
      </Column>
    </DataTable>
  </template>
  
  <script>
  import LoggedUserMixin from '@/mixins/logged-user';
  import { mapActions,mapGetters } from 'vuex';
  export default {
    name: 'adminDataTableModel',
    mixins:[LoggedUserMixin],
    props: ['adminrefactoListAnswers','invalidQuestionIds'],
    data(){
      return{
        list:[
          {
            valid:true,
          },
          {
            invalid:false,
          }
        ]
      }
    },
    computed: {
      ...mapGetters({
          getToReturnSelected: 'localContent/getToReturnSelected',
          getToReturn: 'localContent/getToReturn',
          getPlanList: 'localContent/getPlanList',
      }),
    },
    methods: {
      ...mapActions({
            selectToReturn: 'localContent/selectToReturn',
        }),
        setStatus(list,val){
          let found = [];
          found = list.filter(e=>e.id === val?.answers?.id);
          if(found > 0) return true;
          return false;
        }
      // onChanged: function () {
      //   this.$emit('changed', this.selectedRankFromList);
      // },
    }
  };
  </script>
  <style>
input[type=checkbox] {
    transform: scale(1.5);
}
  .p-fluid .p-inputnumber {
    max-width: 19em;
    margin-left: 0.5em;
  }
  </style>
  