<template>
  <div class="stepsdemo-content">
    <Card>
      <template #content>
        <!-- {{ this.getPlanInfos }} -->
        <!-- {{ this.isAdminRole() }} -->
        <!-- {{ getPlanList }} -->
        <!-- {{ this.isValidatorPublicationRole() || this.isAdminRole() || this.isSimpleAdminRole() }} -->
        <div class="p-fluid p-d-flex" :style="{ width: '100%' }">
          <admin-data-table
            v-if="this.isValidatorPublicationRole() || this.isAdminRole() || this.isSimpleAdminRole()"
            :adminrefactoListAnswers="adminrefactoListAnswers"
            :invalidQuestionIds="invalidQuestionIds"
          ></admin-data-table>
          <data-table-model
            v-else
            :refactoListAnswers="refactoListAnswers"
            :invalidQuestionIds="invalidQuestionIds"
          ></data-table-model>
        </div>
      </template>
      <template #footer>
        <p             v-if="
            (!(this.isValidatorPublicationRole() || this.isAdminRole() || this.isSimpleAdminRole()) &&
            (getPlanList?.data[0].status !== 'F_CREATED') && (getPlanList?.data[0].status !== 'F_REJECTED'))">
          <small :style="{ color: '#ef3636', fontStyle: 'italic' }"
            >Toutes les valeurs de cette section sont requises (*).
          </small>
        </p>
        <div class="p-fluid p-d-flex p-jc-start" :style="{ gap: '1em', 'margin-top': '2em' }">
          <Button
            label="Enregistrer les informations"
            v-if="
            (!(this.isValidatorPublicationRole() || this.isAdminRole() || this.isSimpleAdminRole()) &&
            (getPlanList?.data[0].status !== 'F_VALIDATED') && (getPlanList?.data[0].status !== 'F_SUBMITTED'))"
            @click="saveAnswers()"
            :style="{ width: '20%' }"
            icon="pi pi-check"
            iconPos="right"
          />
          <Button
            label="Suivant"
            @click="gotoSalaryStep()"
            :style="{ width: '20%' }"
            icon="pi pi-angle-right"
            iconPos="right"
          />
        </div>
      </template>
    </Card>
  </div>
</template>
<script>
import dataTableModel from '@/components/local-content/component/data-table-model';
import adminDataTable from '@/components/local-content/component/admin-data-table';
import localContentJobsMixin from '@/mixins/local-content-jobs-mixin';

export default {
  mixins: [localContentJobsMixin],
  data() {
    return {
      invalidQuestionIds: [],
      jobsQuestionsListMock: {},
      jobsAnswersListMock: {},
      refactoListAnswers: [],
      adminrefactoListAnswers: [],
    };
  },
  mounted(){
    this.refactoListAnswers = [];
    this.adminrefactoListAnswers = [];
  },
  components: {
    dataTableModel,
    adminDataTable,
  },
};
</script>
<style scoped>
.p-card {
  box-shadow: none;
}
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>